import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import type { FC } from 'react';

import { useDashboard } from '@/stores/useDashboardStore';

import Button from '../Button';
import DebouncedTextInputField from '../Form/Fields/DebouncedTextInputField';
import Text from '../Text';

type Props = {
  onSearchChange: (search: string) => void;
  search: string;
};
const DashboardControls: FC<Props> = ({ onSearchChange, search }) => {
  const { setJobMatchingActive, jobMatchingActive } = useDashboard();
  return (
    <motion.div layout className="flex flex-1 flex-wrap items-center gap-3">
      <DebouncedTextInputField
        prefixIcon={faSearch}
        placeholder="I am looking for..."
        value={search}
        onChange={(e) => onSearchChange(e.target.value)}
        canHaveErrorMessage={false}
        className="flex-1"
        variant="grayOutline"
        containerId="joyride-search"
      />
      <Button
        className="group h-full"
        variant="primary"
        onClick={() => setJobMatchingActive(!jobMatchingActive)}
      >
        <Icon icon="heroicons-solid:puzzle-piece" />
        <Text as="span" className="font-semibold" variant="body-caption">
          Job Matching
        </Text>
        <Text
          variant="body-caption"
          className="absolute -right-8 top-3 z-10 rotate-[50deg] bg-gradient-to-l from-[#885AFD] to-[#00193F] px-9 font-semibold"
        >
          BETA
        </Text>
      </Button>
    </motion.div>
  );
};

export default DashboardControls;
