import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { useExternalCandidatesCollections } from '@/hooks/useExternalCandidatesCollections';
import { queries } from '@/queries';
import { getNumberParams } from '@/utils/queryParams';

import Text from '../Text';
import AddCollectionDialog from './AddCollectionDialog';
import ButtonWithLabelOnHover from './ButtonWithLabelOnHover';

const Collections: FC = () => {
  const router = useRouter();
  const { collection: collectionParam } = router.query;
  const parsedCollection = getNumberParams(collectionParam);
  const { data: collectionsData } = useExternalCandidatesCollections();
  const { data: profilesListData } = useQuery({
    ...queries.externalCandidates.list({
      pagination: { pageIndex: 0, pageSize: 1 },
    }),
    staleTime: 5 * 60 * 1000,
  });
  return (
    <div className="mb-2 flex w-full shrink-0 gap-4 overflow-auto">
      <div className="flex gap-4 overflow-auto">
        <Link
          className={classNames('flex gap-2', {
            'text-primary-500 border-b-4 border-primary-500 pb-2':
              !parsedCollection,
          })}
          href="/"
          replace
        >
          <Text>All</Text>
          <div
            className={classNames(
              'rounded-xl h-min min-w-[2rem] text-center bg-neutral-100 px-1 font-semibold text-neutral-1000',
              {
                'text-primary-500': !parsedCollection,
              }
            )}
          >
            {profilesListData?.count}
          </div>
        </Link>
        <div className="mb-2 border-r" />
        {collectionsData?.results.map((collection) => (
          <Link
            href={`?collection=${collection.id}`}
            replace
            key={collection.id}
            className={classNames('flex gap-2', {
              'text-primary-500 border-b-4 border-primary-500 pb-2':
                collection.id === parsedCollection,
            })}
          >
            <Text className="max-w-[10rem] truncate whitespace-nowrap">
              {collection.title}
            </Text>
            <div
              className={classNames(
                'rounded-xl h-min  bg-neutral-100 min-w-[2rem] text-center px-1 font-semibold text-neutral-1000',
                {
                  'text-primary-500': collection.id === parsedCollection,
                }
              )}
            >
              {collection.candidates.length}
            </div>
          </Link>
        ))}
      </div>
      <div className="flex h-9 gap-4 pb-3">
        <AddCollectionDialog dialogTriggerProps={{ asChild: true }}>
          <ButtonWithLabelOnHover icon={faPlus}>
            New collection
          </ButtonWithLabelOnHover>
        </AddCollectionDialog>
      </div>
    </div>
  );
};

export default Collections;
