import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { Label } from '@radix-ui/react-dropdown-menu';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { type FC, type PropsWithChildren, useState } from 'react';
import { Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import type { InferType } from 'yup';
import { array, boolean, object, string } from 'yup';

import useForm from '@/hooks/useForm';
import { useSettingsQuery } from '@/hooks/useSettingsQuery';
import { queries } from '@/queries';
import {
  AdjustToOfferFields,
  postCVContextFromCandidateId,
} from '@/services/cvGenerator';
import {
  TranslateLanguages,
  translateLanguagesOptions,
} from '@/services/settings';

import Button from '../Button';
import CheckboxList from '../Form/Fields/CheckboxList';
import MultiLineInput from '../Form/Fields/MultiLineInput';
import TextInputField from '../Form/Fields/TextInputField';
import { Select } from '../Select';
import Text from '../Text';
import { Accordion, AccordionContent, AccordionItem } from '../ui/accordion';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { AdjustToOfferFieldsOptions } from './AddCVForm';
import ForceGenerateDialog from './ForceGenerateDialog';

type Props = {
  profileId: number;
  dialogProps?: DialogProps;
  dialogContentProps?: DialogContentProps;
  dialogTriggerProps?: DialogTriggerProps;
};
const GenerateCVDialog: FC<PropsWithChildren<Props>> = ({
  dialogContentProps,
  dialogProps,
  dialogTriggerProps,
  children,
  profileId,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [forceGenerateDialogOpen, setForceGenerateDialogOpen] = useState(false);
  const [isAdjustToOfferEnable, setIsAdjustToOfferEnable] = useState(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const { data: settingsData } = useSettingsQuery();
  const { mutate, isLoading } = useMutation({
    mutationFn: postCVContextFromCandidateId,
    onSuccess: () => {
      queryClient.invalidateQueries(queries.cvGenerator.list._def);
      queryClient.invalidateQueries(queries.externalCandidates.list._def);
      setDialogOpen(false);
    },
    onError: (err) => {
      if (!(err instanceof AxiosError)) return;
      if (err.response?.status === 420) return setForceGenerateDialogOpen(true);
      if (err.response?.status === 403) {
        toast.error(err?.response?.data?.detail ?? 'Something went wrong');
        return router.push('/pricing');
      }
      toast.error(err?.response?.data?.detail ?? 'Something went wrong');
    },
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      language: settingsData?.preferredCvLanguage,
      offerDescription: '',
      offerTitle: '',
      fieldsToAdjustToOffer: AdjustToOfferFieldsOptions.map(
        (option) => option.value
      ),
      skipAnonymization: false,
    },
    context: { isAdjustToOfferEnable },
  });
  return (
    <Dialog onOpenChange={setDialogOpen} open={dialogOpen} {...dialogProps}>
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent {...dialogContentProps}>
        <DialogHeader>Generate resume</DialogHeader>
        <form
          onSubmit={handleSubmit(
            ({
              skipAnonymization,
              language,
              offerDescription,
              offerTitle,
              fieldsToAdjustToOffer,
            }) =>
              mutate({
                candidateId: profileId,
                skipAnonymization: !skipAnonymization,
                language,
                offerDescription: isAdjustToOfferEnable ? offerDescription : '',
                offerTitle: isAdjustToOfferEnable ? offerTitle : '',
                fieldsToAdjustToOffer: isAdjustToOfferEnable
                  ? fieldsToAdjustToOffer ?? []
                  : [],
                forceGenerate: false,
              })
          )}
          className="flex flex-col gap-3"
        >
          <Controller
            control={control}
            name="language"
            render={({ field: { name, onChange, value } }) => (
              <Select
                classNames={{
                  singleValue: () => '!font-normal',
                }}
                className="flex-1"
                labelClassName="text-neutral-1000"
                label="Language"
                value={translateLanguagesOptions.find(
                  ({ value: optionValue }) => optionValue === value
                )}
                onChange={(selectedOption) => onChange(selectedOption?.value)}
                variant="transparent"
                options={translateLanguagesOptions}
                name={name}
              />
            )}
          />
          <div className="flex flex-col gap-4">
            <div className="felx-row flex justify-between">
              <Label className="flex items-center gap-2 text-neutral-1000">
                <Icon icon="bi:stars" className="text-primary-600" /> Adjust to
                offer{' '}
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipContent className="max-w-[13rem] rounded-lg bg-neutral-200">
                      <Text className="text-neutral-1000">
                        Paste the Job offer and adjust the resume by sorting the
                        skills and improving candidates’ summary.
                      </Text>
                    </TooltipContent>
                    <TooltipTrigger>
                      <Icon
                        icon="ri:question-line"
                        className="text-xl text-neutral-300"
                      />
                    </TooltipTrigger>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Switch
                checked={isAdjustToOfferEnable}
                onCheckedChange={() =>
                  setIsAdjustToOfferEnable((prev) => !prev)
                }
              />
            </div>
            <Accordion
              type="single"
              value={isAdjustToOfferEnable ? 'enable' : undefined}
            >
              <AccordionItem value="enable" className="border-none">
                <AccordionContent className="text-neutral-1000">
                  <div className="flex flex-col gap-4">
                    <TextInputField
                      variant="transparent"
                      label="Job Title"
                      {...register('offerTitle')}
                      error={errors.offerTitle?.message}
                    />
                    <MultiLineInput
                      rows={4}
                      variant="transparent"
                      label="Job Description"
                      {...register('offerDescription')}
                      error={errors.offerDescription?.message}
                    />
                    <CheckboxList
                      labelClassName="!p-0 color-primary-500"
                      checkboxClassName="bg-transparent border-transparent b-0"
                      checkboxCheckIconColor="white"
                      checkboxValueClassName={(checked) =>
                        classNames({
                          '!bg-primary-800 !text-neutral-100 border-primary-800':
                            checked,
                        })
                      }
                      label="Fields to adjust"
                      values={watch('fieldsToAdjustToOffer') ?? []}
                      options={AdjustToOfferFieldsOptions}
                      {...register('fieldsToAdjustToOffer')}
                      error={errors.fieldsToAdjustToOffer?.message}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="flex items-center gap-2 rounded-lg py-4">
            <Icon icon="mdi:incognito" className="text-xl text-neutral-1000" />
            <Label className="flex flex-1 items-center justify-between text-center text-neutral-1000">
              <div className="flex items-center gap-2">
                Anonymize this resume
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipContent className="max-w-[13rem] rounded-lg bg-neutral-200">
                      <Text className="text-neutral-1000">
                        Remove sensitive data from this resume (e.g., surname
                        and contact information).
                      </Text>
                    </TooltipContent>
                    <TooltipTrigger>
                      <Icon
                        icon="ri:question-line"
                        className="text-xl text-neutral-300"
                      />
                    </TooltipTrigger>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <Controller
                control={control}
                name="skipAnonymization"
                render={({ field: { onChange, value, name, onBlur, ref } }) => (
                  <Switch
                    checked={!value}
                    onCheckedChange={onChange}
                    name={name}
                    onBlur={onBlur}
                    ref={ref}
                  />
                )}
              />
            </Label>
          </div>
          <Button isLoading={isLoading} type="submit">
            Submit
          </Button>
        </form>
        <ForceGenerateDialog
          onOpenChange={setForceGenerateDialogOpen}
          open={forceGenerateDialogOpen}
          onCancel={() => {
            mutate({
              ...watch(),
              fieldsToAdjustToOffer: watch('fieldsToAdjustToOffer') ?? [],
              forceGenerate: false,
              candidateId: profileId,
            });
          }}
          isLoading={isLoading}
          onForceGenerate={() => {
            mutate({
              ...watch(),
              fieldsToAdjustToOffer: watch('fieldsToAdjustToOffer') ?? [],
              forceGenerate: true,
              candidateId: profileId,
            });
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default GenerateCVDialog;

const schema = object({
  language: string()
    .required()
    .oneOf(Object.values(TranslateLanguages))
    .required(),
  skipAnonymization: boolean().defined(),
  offerTitle: string().when('$isAdjustToOfferEnable', {
    is: true,
    then: (value) => value.required('Job title is required'),
  }),
  offerDescription: string().when('$isAdjustToOfferEnable', {
    is: true,
    then: (value) => value.required('Job description is required'),
  }),
  fieldsToAdjustToOffer: array()
    .of(string().oneOf(Object.values(AdjustToOfferFields)).required())
    .required()
    .when('$isAdjustToOfferEnable', {
      is: true,
      then: (value) => value.min(1, 'At least one field is required'),
    }),
});
